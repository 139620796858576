define("discourse/plugins/framework-segment/discourse/initializers/framework-segment", ["exports", "discourse/lib/plugin-api", "discourse/lib/cookie"], function (_exports, _pluginApi, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function storeUrl() {
    try {
      sessionStorage.setItem('lastUrl', document.location.href);
    } catch (ignore) {}
  }
  ;
  function getLastUrl() {
    try {
      const lastUrl = sessionStorage.getItem('lastUrl');
      return lastUrl || document.referrer;
    } catch (ignore) {
      return document.referrer;
    }
  }
  ;
  function initializePlugin(api) {
    const enabled = Discourse.SiteSettings.framework_settings_enabled && Discourse.SiteSettings.framework_segment_enabled;
    const writeKey = Discourse.SiteSettings.framework_segment_client_write_key;
    if (!enabled || !writeKey) {
      return;
    }

    /* eslint-disable */
    !function () {
      var analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");else {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = writeKey;
        analytics.SNIPPET_VERSION = "4.15.2";
        /* eslint-enable */
        analytics.load(writeKey);
        const anonymousId = (0, _cookie.default)('anonymous_id');
        analytics.setAnonymousId(anonymousId);
      }
    }();
    api.onPageChange(() => {
      const url = getLastUrl();
      storeUrl();
      const properties = {
        url: document.location.href,
        title: document.title,
        path: document.location.pathname,
        referrer: url
      };
      window.analytics.page(null, properties);
      const currentUser = api.getCurrentUser();
      if (currentUser?.external_id) {
        const {
          external_id,
          email,
          username
        } = currentUser;
        window.analytics.identify(external_id, {
          email: email,
          username: username
        });
      }
    });
  }
  ;
  var _default = _exports.default = {
    name: 'framework-segment',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11', api => initializePlugin(api));
    }
  };
});